import React, { useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft, Github, ExternalLink, Code, Zap, Target, BookOpen } from 'lucide-react';

const ProjectDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  // In a real application, you would fetch this data from an API or database
  const projectDetails = {
    1: {
      name: "Ainder",
      category: "Social Networking, AI Interaction",
      image: "assets/Ainder_Cover.png",
      description: "Ainder is a groundbreaking app that uses AI to simulate real human interaction. Similar to Tinder, users can swipe right or left to choose their preferred AI character for engaging conversations, making social interaction more dynamic and fun.",
      story: "Ainder was developed while I was working at Ouaz Games. Recognizing the potential of AI in enhancing social experiences, our team set out to create an app that allows users to connect with lifelike AI characters. By integrating advanced AI algorithms, we aimed to provide a unique platform where users could enjoy meaningful interactions. The project evolved into a feature-rich application with real-time messaging and payment integration for seamless user experience on both Google Play and the App Store.",
      challenges: [
        "Creating lifelike AI interactions that feel authentic and engaging",
        "Implementing real-time messaging for smooth communication",
        "Ensuring data privacy and security for user interactions"
      ],
      solutions: [
        "Developed sophisticated AI algorithms to enhance character interactions",
        "Utilized Firebase for real-time messaging capabilities",
        "Implemented robust security measures and user consent protocols to protect personal data"
      ],
      technologies: [
        "Flutter",
        "Firebase",
        "RESTful APIs",
        "AI Integration",
        "Cloud Storage",
        "Payment Integration"
      ],
      githubLink: null,
      liveLink: null
    },
    2: {
      name: "Retailink",
      categor: "Supply Chain Management, Logistics",
      image: "assets/Retailink_Cover.png",
      description: "Retailink is a comprehensive app designed for Eadis, a leading distributor of tobacco products across Africa. The app streamlines the delivery system and connects wholesalers, Points de Vente (PDVs), and delivery personnel for efficient operations.",
      story: "Retailink was conceived to address the challenges faced by Eadis in managing its vast distribution network across multiple countries in Africa. The idea emerged from the need for a centralized platform to enhance communication and logistics between wholesalers and PDVs. With a dedicated team, we set out to create an app that not only optimizes delivery routes but also provides valuable insights into sales trends and inventory management. The project evolved from an initial concept into a powerful tool that transforms how Eadis operates in markets like Mali, Mauritania, and Morocco.",
      challenges: [
        "Coordinating deliveries across diverse regions with varying infrastructure",
        "Integrating real-time tracking for improved logistics management",
        "Ensuring data accuracy and security for sensitive business information"
      ],
      solutions: [
        "Developed a centralized dashboard for real-time monitoring of deliveries",
        "Utilized advanced routing algorithms to optimize delivery paths",
        "Implemented robust data security measures and regular audits to protect information"
      ],
      technologies: [
        "Flutter",
        "Firebase",
        "RESTful APIs",
        "SQLite",
        "Geolocation Services",
        "Data Analytics"
      ],
      githubLink: null,
      liveLink: null
    },
    3: {
      "name": "Aidra Driver",
      "category": "Logistics & Waste Management",
      "image": "assets/aidra_cover.png",
      "description": "Aidra Driver is a specialized mobile application designed for waste collection drivers, streamlining the process of collecting and managing waste from restaurants and other establishments. The app provides real-time tracking, collection verification, and efficient route management.",
      "story": "Developed to modernize waste management operations by digitizing collection processes and improving operational efficiency for drivers and management.",
      "challenges": [
        "Managing complex collection routes and schedules efficiently",
        "Tracking and verifying waste collection volumes accurately",
        "Implementing secure authentication for drivers",
        "Coordinating real-time location updates and navigation",
        "Ensuring offline functionality for areas with poor connectivity"
      ],
      "solutions": [
        "Implemented Google Maps integration for efficient route navigation",
        "Developed a robust collection tracking system with volume verification",
        "Created secure local storage using Isar database and flutter_secure_storage",
        "Integrated barcode scanning for quick and accurate collection verification",
        "Built a comprehensive driver authentication system with biometric support"
      ],
      "technologies": [
        "Flutter",
        "Google Maps API",
        "Isar Database",
        "RESTful APIs",
        "Biometric Authentication",
        "Barcode Scanning",
        "Offline Data Sync"
      ],
      "features": [
        "Real-time GPS tracking",
        "Collection volume management",
        "Secure authentication",
        "Offline data storage",
        "Route optimization",
        "Digital collection verification"
      ],
      "githubLink": null,
      "liveLink": null
    },
    4: {
      name: "Accredited Wallet",
      category: "Finance, Mobile Wallet",
      image: "assets/Accredited_Wallet_Cover.png",
      description: "Accredited Wallet is your safe deposit box for shares, enabling users to connect their mobile wallet to a Brokerbot for easy stock purchases. The app ensures that all transactions are automatically recorded in the company’s share register, providing seamless management of your investments.",
      story: "The Accredited Wallet project was developed while I was working full-time at Vodinit. Recognizing the need for a secure and transparent way to manage stock investments, our team set out to create a mobile wallet that integrates with a Brokerbot. By utilizing blockchain technology, we aimed to enhance security and transparency in tracking shares. The project evolved into a robust application that allows users to buy stocks, monitor their investments, and access their shares anytime, with all transactions securely logged on an electronic ledger.",
      challenges: [
        "Ensuring data security and compliance with financial regulations",
        "Integrating blockchain technology for real-time updates",
        "Providing a user-friendly interface for complex financial transactions"
      ],
      solutions: [
        "Implemented robust encryption and authentication methods to safeguard user data",
        "Utilized Spring Boot for the backend to ensure efficient integration with blockchain ledgers",
        "Designed an intuitive interface in Flutter to simplify the user experience for managing investments"
      ],
      technologies: [
        "Flutter",
        "Spring Boot",
        "Blockchain Technology",
        "RESTful APIs",
        "SQLite"
      ],
      githubLink: null,
      liveLink: "https://apps.apple.com/us/app/accredited-wallet/id1560868130"
    }
    
  };

  const project = projectDetails[id];

  if (!project) {
    return <div className="text-center text-2xl text-gray-400 mt-20">Project not found</div>;
  }

  return (
    <div className="bg-[#0a0a0a] text-white min-h-screen">
      <div className="fixed inset-0 z-0">
        <div className="absolute inset-0 bg-gradient-to-br from-green-900/10 via-blue-900/10 to-purple-900/10"></div>
        <div className="absolute top-0 left-0 right-0 h-[500px] bg-gradient-to-b from-green-500/5 via-blue-500/5 to-transparent blur-3xl"></div>
      </div>

      <div className="max-w-6xl mx-auto px-4 py-12 md:py-24 relative z-10">
        <motion.a 
          onClick={handleBack}
          className="inline-flex items-center space-x-2 text-gray-400 hover:text-white mb-12 group"
          whileHover={{ x: -5 }}
          transition={{ type: "spring", stiffness: 400 }}
        >
          <ArrowLeft className="transition-transform group-hover:-translate-x-1" size={20} />
          <span>Back to Portfolio</span>
        </motion.a>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="space-y-16"
        >
          <header className="text-center space-y-6">
            <motion.h1 
              className="text-6xl font-bold bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 text-transparent bg-clip-text"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              {project.name}
            </motion.h1>
            <motion.p 
              className="text-2xl text-gray-400"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              {project.category}
            </motion.p>
          </header>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <motion.div 
              className="space-y-8"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4 }}
            >
              <div className="bg-gray-800/30 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50">
                <h2 className="text-2xl font-semibold mb-4 flex items-center space-x-3">
                  <Code className="text-green-400" />
                  <span>Project Overview</span>
                </h2>
                <p className="text-gray-300 leading-relaxed">{project.description}</p>
              </div>

              <div className="bg-gray-800/30 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50">
                <h2 className="text-2xl font-semibold mb-4 flex items-center space-x-3">
                  <BookOpen className="text-green-400" />
                  <span>Project Story</span>
                </h2>
                <p className="text-gray-300 leading-relaxed">{project.story}</p>
              </div>
            </motion.div>

            <motion.div 
              className="space-y-8"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.5 }}
            >
              <div className="bg-gray-800/30 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50">
                <h2 className="text-2xl font-semibold mb-4 flex items-center space-x-3">
                  <Target className="text-green-400" />
                  <span>Key Challenges</span>
                </h2>
                <ul className="space-y-4">
                  {project.challenges.map((challenge, index) => (
                    <motion.li 
                      key={index}
                      className="flex items-start space-x-3"
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.6 + index * 0.1 }}
                    >
                      <span className="text-green-400 text-lg">•</span>
                      <span className="text-gray-300">{challenge}</span>
                    </motion.li>
                  ))}
                </ul>
              </div>

              <div className="bg-gray-800/30 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50">
                <h2 className="text-2xl font-semibold mb-4 flex items-center space-x-3">
                  <Zap className="text-green-400" />
                  <span>Solutions</span>
                </h2>
                <ul className="space-y-4">
                  {project.solutions.map((solution, index) => (
                    <motion.li 
                      key={index}
                      className="flex items-start space-x-3"
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.7 + index * 0.1 }}
                    >
                      <span className="text-green-400 text-lg">•</span>
                      <span className="text-gray-300">{solution}</span>
                    </motion.li>
                  ))}
                </ul>
              </div>
            </motion.div>
          </div>

          <motion.div 
            className="bg-gray-800/30 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8 }}
          >
            <h2 className="text-2xl font-semibold mb-6">Technologies Used</h2>
            <div className="flex flex-wrap gap-3">
              {project.technologies.map((tech, index) => (
                <motion.span
                  key={index}
                  className="bg-gradient-to-r from-green-400 to-blue-500 p-[1px] rounded-full"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.9 + index * 0.1 }}
                >
                  <div className="bg-gray-900 px-4 py-2 rounded-full">
                    {tech}
                  </div>
                </motion.span>
              ))}
            </div>
          </motion.div>

          {(project.githubLink || project.liveLink) && (
            <motion.div 
              className="flex justify-center space-x-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 1 }}
            >
              {project.githubLink && (
                <motion.a
                  href={project.githubLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group relative overflow-hidden rounded-lg bg-gradient-to-r from-gray-700 to-gray-600 p-[1px]"
                  whileHover={{ scale: 1.05 }}
                >
                  <div className="relative bg-gray-900 px-6 py-3 rounded-lg group-hover:bg-opacity-80 transition-all duration-300 flex items-center space-x-2">
                    <Github size={20} />
                    <span>View on GitHub</span>
                  </div>
                </motion.a>
              )}
              {project.liveLink && (
                <motion.a
                  href={project.liveLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group relative overflow-hidden rounded-lg bg-gradient-to-r from-green-400 to-blue-500 p-[1px]"
                  whileHover={{ scale: 1.05 }}
                >
                  <div className="relative bg-gray-900 px-6 py-3 rounded-lg group-hover:bg-opacity-80 transition-all duration-300 flex items-center space-x-2">
                    <ExternalLink size={20} />
                    <span>Live Demo</span>
                  </div>
                </motion.a>
              )}
            </motion.div>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default ProjectDetails;