import React, { useState, useEffect, useMemo } from 'react';
import { ArrowUpRight, Github, Linkedin, Mail, Menu, X, Download } from 'lucide-react';
import { motion } from 'framer-motion';
import CodeElement from '../components/CodeElement';
import { Link } from 'react-router-dom';
import ParticlesBackground from '../components/ParticlesBackground';

const Portfolio = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('home');
  const navItems = useMemo(() => ['Home', 'Work', 'Skills', 'Experience', 'Education', 'Contact'], []);
  
  const projects = [
    {
      id: 1,
      name: 'Ainder Mobile App',
      category: 'Flutter, Firebase, API connections, ...',
      image: 'assets/Cover.png'
    },
    {
      id: 2,
      name: 'Retailink (Delivery Workflow)',
      category: 'Flutter, Notifications, Websockets, ...',
      image: 'assets/retailink.webp'
    },
    {
      id: 3,
      name: 'Aidra drive',
      category: 'Full-stack Development',
      image: 'assets/aidra_drive.png'
    },
    {
      id: 4,
      name: 'Accredited Wallet',
      category: 'Full-stack Development',
      image: 'assets/crypto_project.png'
    },
  ];
  
  const skills = [
    { name: 'Flutter (Dart)', icon: '🎯' },
    { name: 'Spring (Java/Kotlin)', icon: '☕' },
    { name: 'React (JS/TS)', icon: '⚛️' },
    { name: 'Tailwind', icon: '🌬️' },
    { name: 'HTML', icon: '🌐' },
    { name: 'CSS', icon: '🎨' },
    { name: 'Firebase', icon: '🔥' },
    { name: 'GIT', icon: '🔄' },
  ];
  
  const experiences = [
    {
      title: 'Mobile Developer',
      company: 'Aidra',
      period: 'Nov 2024 - Present',
      description: 'Developed mobile applications using Flutter framework, integrating Firebase for backend services.'
    },
    {
      title: 'Mobile Developer',
      company: 'Ouaz games',
      period: 'Jan 2024 - Nov 2024',
      description: 'Developed mobile applications using Flutter framework, integrating Firebase for backend services.'
    },
    {
      title: 'Freelance Mobile Developer',
      company: 'EADIS sarl',
      period: 'Sep 2023 - Jan 2024',
      description: 'Designed and developed a delivery workflow management app for a multinational tobacco distributor.'
    },
    {
      title: 'Full-stack Developer',
      company: 'VodinIT',
      period: 'Mar 2023 - Aug 2023',
      description: 'Spearheaded development of "Bazar Commerce" dashboard and contributed to crypto projects.'
    }
  ];

  const education = [
    {
      degree: 'Master of Software Engineering and Information Systems Management',
      school: 'ENSA Kenitra',
      period: '2022 - 2024'
    },
    {
      degree: 'Licence, Web and Mobile Application Development',
      school: 'Ibn Tofail University',
      period: '2021 - 2022'
    }
  ];

  const socialNetworks = [
    { name: 'Github', icon: Github, link: 'https://github.com/redaantar' },
    { name: 'LinkedIn', icon: Linkedin, link: 'https://www.linkedin.com/in/redaantar/' },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      
      const sections = navItems.map(item => document.getElementById(item.toLowerCase()));
      
      let newActiveSection = '';

      if (scrollPosition + windowHeight === documentHeight) {
        newActiveSection = navItems[navItems.length - 1].toLowerCase();
      } else {
        for (let i = sections.length - 1; i >= 0; i--) {
          const section = sections[i];
          if (section) {
            const sectionTop = section.offsetTop;
            const sectionBottom = sectionTop + section.offsetHeight;
            if (scrollPosition >= sectionTop - 100 && scrollPosition < sectionBottom - 100) {
              newActiveSection = navItems[i].toLowerCase();
              break;
            }
          }
        }
      }

      if (newActiveSection !== activeSection) {
        setActiveSection(newActiveSection);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, [activeSection, navItems]);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -80;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
    setIsDrawerOpen(false);
  };

  return (
    <div className="bg-[#0a0a0a] text-white min-h-screen">
      <ParticlesBackground />
      <div className="fixed inset-0 z-0 pointer-events-none">
        <div className="absolute inset-0 bg-gradient-to-br from-green-900/10 via-blue-900/10 to-purple-900/10"></div>
        <div className="absolute top-0 left-0 right-0 h-[500px] bg-gradient-to-b from-green-500/5 via-blue-500/5 to-transparent blur-3xl"></div>
      </div>
      <header className="fixed top-0 left-0 right-0 backdrop-blur-md bg-black/10 z-50 border-b border-white/5">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <nav className="flex justify-between items-center h-20">
            <div className="text-3xl font-bold bg-gradient-to-r from-green-400 to-blue-500 text-transparent bg-clip-text">RA.</div>
            <ul className="hidden md:flex space-x-6">
              {navItems.map((item, index) => (
                <li key={index} className={`${item.toLowerCase() === activeSection ? 'text-green-400' : 'text-gray-400 hover:text-white transition-colors'}`}>
                  <button onClick={() => scrollToSection(item.toLowerCase())}>{item}</button>
                </li>
              ))}
            </ul>
            <button onClick={() => setIsDrawerOpen(true)} className="md:hidden text-white">
              <Menu size={24} />
            </button>
          </nav>
        </div>
      </header>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16">
        <main className="py-16">
        <motion.section 
          id="home" 
          className="mb-24 pt-12 md:pt-24 relative"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="lg:w-1/2 relative z-10">
            <h1 className="text-5xl md:text-7xl font-bold mb-6 leading-tight">
              <span className="bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 text-transparent bg-clip-text">
                Hello, I'm Reda Antar
              </span>
            </h1>
            <p className="text-xl text-gray-400 mb-12 max-w-2xl">
              Full-Stack Developer specializing in Flutter, Spring, and React. Passionate about creating efficient and user-friendly applications.
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <a href="mailto:mrredaantar@gmail.com" 
                className="group relative bg-gradient-to-r from-green-400 to-blue-500 p-[1px] rounded-full overflow-hidden">
                <div className="relative bg-gray-900 text-white px-8 py-3 rounded-full group-hover:bg-opacity-90 transition-all duration-300 flex items-center justify-center">
                  Connect <Mail className="ml-2" size={20} />
                </div>
              </a>
              <a href="assets/cv.pdf" download className="bg-blue-500 text-white px-8 py-3 rounded-full font-semibold text-lg hover:bg-blue-400 transition-colors inline-flex items-center justify-center">
                Download CV <Download className="ml-2" size={20} />
              </a>
            </div>
          </div>
          <CodeElement />
        </motion.section>
        <motion.section 
          id="work" 
          className="mb-24 pt-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <h2 className="text-3xl font-bold mb-4 flex items-center">
            <span className="bg-green-400 w-6 h-1 mr-2"></span>
            Work
          </h2>
          <p className="text-gray-400 mb-8">Some of the projects I've worked on recently.</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {projects.map((project, index) => (
              <motion.div 
                key={index} 
                className="group bg-gray-800/50 backdrop-blur-sm rounded-xl overflow-hidden shadow-lg hover:shadow-green-400/20 transition-all duration-300 border border-gray-700/50"
                whileHover={{ scale: 1.03, y: -5 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <div className="relative overflow-hidden">
                  <img src={project.image} alt={project.name} className="w-full h-64 object-cover transform group-hover:scale-110 transition-transform duration-500" />
                  <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-60"></div>
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2">{project.name}</h3>
                  <p className="text-sm text-gray-400 mb-4">{project.category}</p>
                  <Link 
                    to={`/project/${project.id}`} 
                    className="text-green-400 flex items-center hover:underline"
                  >
                    View Project <ArrowUpRight className="ml-1" size={18} />
                  </Link>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section 
          id="skills" 
          className="mb-24 pt-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <h2 className="text-3xl font-bold mb-4 flex items-center">
            <span className="bg-green-400 w-6 h-1 mr-2"></span>
            Skills
          </h2>
          <p className="text-gray-400 mb-8">Technologies and tools I specialize in:</p>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {skills.map((skill, index) => (
              <motion.div 
                key={index} 
                className="bg-gray-800/30 backdrop-blur-sm p-8 rounded-xl shadow-lg border border-gray-700/50 hover:border-green-400/50 transition-all duration-300 flex flex-col items-center justify-center text-center"
                whileHover={{ scale: 1.05, y: -5 }}
                transition={{ type: "spring", stiffness: 300 }}
              >
                <span className="text-5xl mb-4">{skill.icon}</span>
                <span className="text-sm font-medium">{skill.name}</span>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section 
          id="experience" 
          className="mb-24 pt-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <h2 className="text-3xl font-bold mb-4 flex items-center">
            <span className="bg-green-400 w-6 h-1 mr-2"></span>
            Experience
          </h2>
          <div className="space-y-8">
            {experiences.map((exp, index) => (
              <motion.div 
                key={index} 
                className="bg-gray-800/30 backdrop-blur-sm p-8 rounded-xl shadow-lg border border-gray-700/50 hover:border-green-400/50"
                whileHover={{ scale: 1.02, y: -5 }}
              >
                <h3 className="text-xl font-semibold mb-2">{exp.title}</h3>
                <p className="text-green-400 mb-2">{exp.company}</p>
                <p className="text-sm text-gray-400 mb-4">{exp.period}</p>
                <p className="text-gray-300">{exp.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section 
          id="education" 
          className="mb-24 pt-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <h2 className="text-3xl font-bold mb-4 flex items-center">
            <span className="bg-green-400 w-6 h-1 mr-2"></span>
            Education
          </h2>
          <div className="space-y-8">
            {education.map((edu, index) => (
              <motion.div 
                key={index} 
                className="bg-gray-800 p-6 rounded-lg shadow-md hover:shadow-green-400/20 transition-all duration-300"
                whileHover={{ scale: 1.02 }}
              >
                <h3 className="text-xl font-semibold mb-2">{edu.degree}</h3>
                <p className="text-green-400 mb-2">{edu.school}</p>
                <p className="text-sm text-gray-400">{edu.period}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        <motion.section 
          id="contact" 
          className="pt-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 1 }}
        >
          <h2 className="text-3xl font-bold mb-4 flex items-center">
            <span className="bg-green-400 w-6 h-1 mr-2"></span>
            Contact
          </h2>
          <p className="text-gray-400 mb-8">Feel free to reach out to me through these platforms:</p>
          <div className="flex flex-wrap gap-6">
            {socialNetworks.map((network, index) => (
              <motion.a 
                key={index} 
                href={network.link} 
                className="flex items-center text-gray-400 hover:text-white transition-colors"
                whileHover={{ scale: 1.1 }}
              >
                <network.icon className="mr-2" size={20} />
                {network.name}
              </motion.a>
            ))}
            <motion.a 
              href="mailto:mrredaantar@gmail.com" 
              className="flex items-center text-gray-400 hover:text-white transition-colors"
              whileHover={{ scale: 1.1 }}
            >
              <Mail className="mr-2" size={20} />
              Email
            </motion.a>
          </div>
        </motion.section>
        </main>

        <footer className="py-6 text-center text-gray-400">
          <p>&copy; 2025 Reda Antar. All rights reserved.</p>
        </footer>
      </div>

      {isDrawerOpen && (
        <motion.div 
          className="fixed inset-0 bg-black bg-opacity-50 z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div 
            className="fixed right-0 top-0 h-full w-64 bg-gray-800 p-4"
            initial={{ x: "100%" }}
            animate={{ x: 0 }}
            exit={{ x: "100%" }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-bold">Menu</h2>
              <button onClick={() => setIsDrawerOpen(false)}>
                <X size={24} />
              </button>
            </div>
            <ul className="space-y-2">
              {navItems.map((item, index) => (
                <li key={index}>
                  <button 
                    onClick={() => scrollToSection(item.toLowerCase())}
                    className={`block py-2 ${item.toLowerCase() === activeSection ? 'text-green-400' : 'text-gray-300 hover:text-white'}`}
                  >
                    {item}
                  </button>
                </li>
              ))}
            </ul>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default Portfolio;